import React from "react";
import styled from "@emotion/styled";
import { useT } from "../../translations/translator";

import SEO from "../seo";
import Container from "../container";
import Footer from "../footer";
import Header from "../header";

const Layout = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
`;

const BlogPage = ({ pageContext }) => {
  const { t } = useT();

  return (
    <Layout>
      <SEO
        title={t("blogTitle")}
        lang={t("language")}
        description={t("blogDescription")}
        context={pageContext}
      />
      <Header />
      <main style={{ overflow: "hidden" }}>
        <Container>
          <h1>{t("blogTitle")}</h1>
          <p>{t("blogText")}</p>
        </Container>
      </main>
      <Footer />
    </Layout>
  );
};

export default BlogPage;
